import React, { Component } from 'react';

class WorkerForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            csgid_first_name: '',
            csgid_last_name: '',
            csgid_address_street: '',
            csgid_address_city: '',
            csgid_address_state: '',
            csgid_address_zip: '',
            csgid_mobile_number: '',
            csgid_email: '',
            csgid_gender: '',
            csgid_field_worker_distance: '',
            csgid_status: 'Submitted'
        };
    }

    handleChange = event => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState(state => ({
            [name]: value
        }), () => console.log(this.state));
    }

    handleSubmit = event => {
        fetch('https://csgdev.appify.com/api/data/v1.0/csgdev/objects/csgid_gig_worker_profile', {
            method: 'POST',
            body: JSON.stringify(this.state),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Authorization": "Basic " + "a3VydC5qYWNvYnNvbkBjc2dpLmNvbToxU3VwZXJEdXBlciE="
            }
        }).then(response => {
            console.log(response);
        });
    }

    render() {
        return (
            <div class="col-md-8 mb-4">
                <div class="card mb-4">
                    <div class="card-header py-3">
                        <h5 class="mb-0">Worker Registration</h5>
                    </div>
                    <div class="card-body">
                        <form onSubmit={this.handleSubmit}>
                            <div class="row">
                                <div class="col">
                                    <div class="form-floating mb-3">
                                        <input class="form-control"
                                            name="csgid_first_name"
                                            type="text"
                                            placeholder="Name"
                                            onChange={this.handleChange} />
                                        <label class="form-label">First Name</label>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-floating mb-3">
                                        <input class="form-control"
                                            name="csgid_last_name"
                                            type="text"
                                            placeholder="Name"
                                            onChange={this.handleChange} />
                                        <label class="form-label">Last Name</label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-floating mb-3">
                                <input class="form-control"
                                    name="csgid_address_street"
                                    type="text"
                                    placeholder="Street Address"
                                    onChange={this.handleChange} />
                                <label class="form-label">Street Address</label>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="form-floating mb-3">
                                        <input class="form-control"
                                            name="csgid_address_city"
                                            type="text"
                                            placeholder="City"
                                            onChange={this.handleChange} />
                                        <label class="form-label">City</label>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-floating mb-3">
                                        <input class="form-control"
                                            name="csgid_address_state"
                                            type="text"
                                            placeholder="State"
                                            onChange={this.handleChange} />
                                        <label class="form-label">State</label>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-floating mb-3">
                                        <input class="form-control"
                                            name="csgid_address_zip"
                                            type="text"
                                            placeholder="Zip Code"
                                            onChange={this.handleChange} />
                                        <label class="form-label">Zip Code</label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-floating mb-3">
                                <input class="form-control"
                                    name="csgid_mobile_number"
                                    type="text"
                                    placeholder="Mobile Number"
                                    onChange={this.handleChange} />
                                <label class="form-label">Mobile Number</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input class="form-control"
                                    name="csgid_email"
                                    type="text"
                                    placeholder="Email Address"
                                    onChange={this.handleChange} />
                                <label class="form-label">Email Address</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input class="form-control"
                                    name="csgid_gender"
                                    type="text"
                                    placeholder="Gender"
                                    onChange={this.handleChange} />
                                <label class="form-label">Gender</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input class="form-control"
                                    name="csgid_field_worker_distance"
                                    type="number"
                                    placeholder="Work Distance"
                                    onChange={this.handleChange} />
                                <label class="form-label">Work Distance</label>
                            </div>
                        </form>
                        <button type="button" class="btn btn-primary pull-right" onClick={this.handleSubmit}>Register</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default WorkerForm;