import './App.css';
import React, { Component } from 'react';
import AppBody from './AppBody';

class App extends Component {
  render() {
    return (
      <div>
        <AppBody />
      </div>
    );
  }
}

export default App;
