import React, { Component } from 'react';
import NavBar from './NavBar';
import WorkerForm from './WorkerForm';

class AppBody extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                {/* <NavBar /> */}
                <WorkerForm />
            </div>
        )
    }
}

export default AppBody;