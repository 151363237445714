import React, { Component } from 'react';

class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <nav class="navbar navbar-expand-md bg-dark navbar-dark">
                <div class="container">
                    <a href="#" class="navbar-brand">Worker Registration</a>
                </div>
            </nav>
        )
    }
}

export default NavBar;